.services-banner {
  background-image: linear-gradient(116deg, #3257a8 -4.92%, #101010 99.11%),
    url("../../assets/img/arrows-group.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  padding-block: 46px 96px;
  h1 {
    color: #fff;
    text-align: center;
    text-transform: none;
  }
}

.scroll-tab-wrap {
  // max-height: 500px;
  // overflow-x: auto;
  margin-top: -40px;
  // &::-webkit-scrollbar {
  //   width: 0;
  // }
  &::-moz-scrollbar {
    width: 0;
  }
  .navbar {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    border-radius: 8px 0px 0px 8px;
    & + div {
      padding-top: 40px;
    }
  }

  .tab_item {
    display: flex;
    align-items: center;
    background: #f9f9ff;
    color: #3257a8;
    text-align: center;
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    padding: 21px 20px;
    max-width: 177px;
    min-height: 80px;
    cursor: pointer;
    &:first-child {
      border-radius: 8px 0px 0px 8px;
    }
    &:last-child {
      border-radius: 0px 8px 8px 0px;
    }
    &.active {
      color: #fff;
      background: #3257a8;
    }
  }
  .serices-tab-data {
    display: flex;
    gap: 80px;
    padding-block: 20px;
    .left-panel {
      width: 60%;
      h2 {
        margin-top: 20px;
        margin-bottom: 40px;
      }
      p {
        margin-bottom: 20px;
      }
    }
    .right-panel {
      img {
        max-height: 331px;
        border-radius: 4px;
      }
    }
  }
}
