.privacy-policy {
  h1 {
    text-align: center;
    text-transform: none;
    padding-block: 16px;
    margin-bottom: 24px;
  }
  h6 {
    color: #3257a8;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  p {
    color: #515151;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 40px;
  }
}
