.project-case-study {
  position: relative;
  background: #542887;
  background-image: linear-gradient(
    140deg,
    rgba(84, 40, 135, 0.6) 15.85%,
    rgba(84, 40, 135, 0.8) 82.39%
  );

  background-repeat: no-repeat;
  background-size: cover;

  .case-study-banner {
    > img {
      opacity: 0.5;
      width: 100%;
    }
    .case-study-hover {
      position: absolute;
      top: 46px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      width: 100%;
      text-align: center;
      h2 {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        line-height: 110%;
        text-transform: none;
        & + h2 {
          font-weight: 800;
        }
      }
      .logo-icon {
        img {
          height: 48px;
          width: auto;
        }
      }
    }
  }
}
