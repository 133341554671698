footer {
  width: 100%;
  padding-inline: 0;
  padding-block: 80px 40px;

  .col-12 {
    @media screen and (max-width: 991px) {
      margin-bottom: 16px;
    }
  }

  ul.common-list {
    li {
      display: block;
      margin-bottom: 16px;
      a {
        color: #101010;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;

        @media screen and (max-width: 991px) {
          font-size: 12px;
        }
      }
    }
  }

  .socail-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-block: 16px;
    margin-top: 60px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: 24px;
    }
    .copyright-info {
      color: rgba(16, 16, 16, 0.5);
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
    .social-links {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }

    &.active {
      border-bottom: 2px solid $color-system-red-primary;
    }
  }
}
