// COMMON CSS START
.flex {
  display: flex;
}

.flex-spacebetweeen-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.align-center {
  align-items: center;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.paddingTB8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.paddingLR8 {
  padding-left: 8px;
  padding-right: 8px;
}

.common-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.link {
  li {
    a {
      color: $color-system-white;
      text-decoration: underline;
    }
  }
}

.text14 {
  font-size: 0.875rem;
}

.linkText {
  color: $color-system-green;
}

.bold {
  font-weight: bold !important;
}

.hide {
  display: none;
}

.hidden {
  visibility: hidden;
}

.mt0 {
  margin-top: 0 !important;
}

.mt2 {
  margin-top: 2px;
}

.mt8 {
  margin-top: 8px !important;
}

.mt16 {
  margin-top: 16px !important;
}

.mt24 {
  margin-top: 24px !important;
}

.mt32 {
  margin-top: 32px !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml8 {
  margin-left: 8px !important;
}

.ml16 {
  margin-left: 16px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb40 {
  margin-bottom: 40px;
}

.mr8 {
  margin-right: 8px !important;
}

.mr16 {
  margin-right: 16px;
}

.mr40 {
  margin-right: 40px;
}

.mb20 {
  margin-bottom: 20px;
}

.errorMessage {
  font-size: 0.875rem;
  color: $color-system-red-primary;
}

.commonProgress {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;

  img {
    height: 56px;
  }
}

.commonLoader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .commonProgress {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100px;
    }
  }
}

.inlineLoaderIcon {
  height: 20px;
  margin-left: 10px;
}

.bulletList,
.numberList {
  li {
    font-family: "Work Sans", sans-serif;
    font-size: 1rem;
    line-height: 25px;
    text-transform: none;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: 1100px) {
      font-size: 0.875rem;
    }
  }
}

.pointer {
  cursor: pointer;
}

.ellip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.threeLinerText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.rotateIcon {
  transform: rotateZ(90deg);
}

.rounded-circle {
  border-radius: 50% !important;
}

.p-0 {
  padding: 0 !important;
}
