.how-clinch-can-help {
  position: relative;
  background: linear-gradient(180deg, #f7f8ff 0%, rgba(247, 248, 255, 0) 100%);
  padding-block: 100px 40px;
  h2 {
    margin-bottom: 40px;
    text-align: center;
    width: 100%;
    position: relative;
    top: 100px;
  }
  .help-box {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    color: #101010;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    width: 100%;

    img {
      width: 24px;
      height: 24px;
    }
  }
  .clinch-help-services-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    .can-help-wrap-left {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: center;
      max-width: 210px;
      .help-left-box {
        padding: 24px;
      }
    }
    .how-clinch-help-join {
      pointer-events: none;
    }
    .can-help-wrap-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: stretch;
      gap: 24px;
      flex: 1;
      max-width: 216px;
      .help-right-box {
        padding: 16px 24px;
      }
    }
  }
}
