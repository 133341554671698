.client-about-clinch {
  background: #fff;
  padding-block: 40px;
  h2 {
    margin-bottom: 40px;
    text-align: center;
  }
  .client-card-wrap {
    display: flex;
    gap: 24px;
    padding-bottom: 40px;

    .client-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px;
      border-radius: 16px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: rgba(50, 87, 168, 0.06);
      h3 {
        display: flex;
        gap: 10px;
        color: #3257a8;
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 16px;
        font-style: italic;
        color: #4e4c4b;
      }
      .client-info-wrap {
        display: flex;
        gap: 8px;
        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
        .client-info {
          .client-name {
            color: #101010;
            font-size: 14px;
            font-style: italic;
            font-weight: 600;
            line-height: 17px;
            margin-bottom: 0;
          }
          .client-designation {
            color: #4e4c4b;
            font-size: 14px;
            font-style: italic;
            font-weight: 300;
            line-height: 17px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
