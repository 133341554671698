h1 {
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  font-weight: $fontWeight700;
  color: #3257a8;
}

h2 {
  font-family: "Work Sans", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  font-weight: $fontWeight700;
  color: #3257a8;
}

h3 {
  font-family: "Work Sans", sans-serif;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: $fontWeight600;
  color: $color-system-heading;
}

h4 {
  font-family: "Work Sans", sans-serif;
  font-size: 22px;
  line-height: 120%;
  font-style: normal;
  font-weight: $fontWeight700;
  color: $color-system-white;
  text-transform: uppercase;
}

h5 {
  font-family: "Work Sans", sans-serif;
  color: #3257a8;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

h6 {
  font-family: "Work Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: $fontWeight600;
  color: $color-system-heading;
}

p,
li,
span,
strong,
em {
  font-family: "Work Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  text-transform: none;
}

a {
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 400;
  font-style: normal;
  color: $color-system-blue;
  text-decoration: none;
}

.body-default-copy {
  font-family: "Work Sans", sans-serif;
  font-size: 1rem;
  line-height: 25px;
  text-transform: none;

  @media screen and (max-width: 1100px) {
    font-size: 0.875rem;
  }
}

.body-default-copy-large {
  font-family: "Work Sans", sans-serif;
  color: #515151;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;

  @media screen and (max-width: 1100px) {
    font-size: 1rem;
  }

  strong,
  b,
  a {
    font-size: 1.125rem;
    line-height: 27px;
  }
}

.body-default-copy-medium {
  font-family: "Work Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 150%;
  text-transform: none;
  color: #101010;
  font-weight: $fontWeight400;
}

.body-default-copy-small {
  font-family: "Work Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 21px;
  text-transform: none;
}
