.all-project-glance {
  background: #fff;
  padding-block: 40px;
  h2 {
    margin-bottom: 40px;
    text-align: center;
  }
  .project-card-wrap {
    display: grid;
    grid-template-columns: auto auto;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    padding-bottom: 40px;
    @media screen and (max-width: 991px) {
      grid-template-columns: auto;
    }
    .project-card {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      &:nth-child(even) {
        margin-top: 32px;
        .project-hover {
          bottom: -8px;
        }
      }
      a > img {
        border-radius: 8px;
        width: 100%;
      }
      .project-hover {
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        left: 0;
        bottom: 24px;
        width: 90%;
        // height: 100%;
        transition: all 2s ease, visibility 1ms;

        .project-hover-btn {
          position: absolute;
          left: 24px;
          bottom: 40px;
          img {
            display: block;
            // max-width: 48px;
            // max-height: 48px;
          }
          h4 {
            text-transform: none;
          }
          .view-case-study-btn {
            display: inline-flex;
            align-items: center;
            gap: 8px;
            padding: 8px 12px;
            border-radius: 40px;
            border: 2px solid #fff;
            background: #fff;
            color: #3257a8;
            font-size: 12px;
            font-weight: 700;
            line-height: normal;
            margin-top: 24px;
            img {
              width: auto;
              height: auto;
            }
          }
        }
      }
      &:hover {
        .project-hover {
          visibility: visible;
          opacity: 1;
          pointer-events: all;
          background: none;
          transition: all 2s ease, visibility 1ms;
        }
      }
    }
  }
}
