.clinch-process {
  background: #eaecf0;
  padding-block: 40px;
  h2 {
    margin-bottom: 40px;
    text-align: center;
  }
  .discovery-card-wrap {
    display: flex;
    gap: 16px;
    padding-bottom: 16px;
    .column-4 {
      width: 40%;
    }
    .column-8 {
      width: 60%;
    }
    .discovery-card {
      padding: 32px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.12);
      h3 {
        display: flex;
        gap: 10px;
        color: #101010;
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 10px;
        &.blue {
          color: #3257a8;
        }
      }
      p {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &.spaced {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
    }
  }
}
