.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  padding: 12px 24px;
  outline: 0;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  vertical-align: middle;
  font-family: "Work Sans", sans-serif;
  font-size: 0.875rem;
  line-height: normal;
  font-weight: $fontWeight700;
  min-width: 64px;
}

.btn-outline-primary {
  @extend .btn;
  background-color: $color-system-white;
  border-radius: 40px;
  border: 2px solid #3257a8;
  color: #3257a8;

  &:hover,
  &:focus {
    color: $color-system-white;
    background-color: #3257a8;
  }
}

.unStyledButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
