.lets-connect {
  background: url("../../assets/img/let-connect-bg.png") no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  padding-block: 40px;

  .flex-spacebetweeen-center {
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: 40px;
    }
  }

  .row {
    @media screen and (max-width: 991px) {
      justify-content: center;

      .address {
        text-align: center;
        p {
          text-align: center;
        }
      }

      .left-panel {
        text-align: center;

        li {
          justify-content: center;
        }
      }

      .right-panel {
        margin-top: 40px;
      }
    }
  }

  .left-panel {
    h4 {
      margin-bottom: 40px;
    }

    li,
    a,
    span {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }
    }

    li {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-bottom: 16px;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .right-panel {
    width: 100%;
    max-width: 380px;
    padding: 40px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);

    @media screen and (max-width: 991px) {
      padding: 16px;
    }
  }

  .address {
    img{
      width: 30px;      
      height: 100%;
      object-fit: contain;      
    }
    text-align: start;

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.6;
      text-align: start;
    }
  }
}


.toast {
  position: fixed;
  top: 100px;
}