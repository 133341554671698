.header {
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: $color-system-white;
  backdrop-filter: blur(8px);
  .logo {
    height: 59px;

    @media screen and (max-width: 1100px) {
      height: 43px;
    }

    img {
      height: 59px;
      object-fit: contain;

      @media screen and (max-width: 1100px) {
        height: 43px;
      }
    }
  }

  .navbar-nav {
    .nav-link {
      color: #101010;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      margin-right: 48px;

      &:active,
      &:focus,
      &:hover,
      &.active {
        color: #3257a8;
        border-bottom: 2px solid #3257a8;
      }
    }

    .nav-link-active {
      color: #101010;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      margin-right: 48px;
      color: #3257a8;
      border-bottom: 2px solid #3257a8;
    }
  }
  .menuBtn {
    display: block;
    cursor: pointer;
    margin-left: 48px;
    border: none;
    .navbar-toggler-icon {
      background-image: url("../../img//ham-menu.svg");
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &.home-header {
    background: #f3f3fa;
    .btn-outline-primary {
      background-color: transparent;
      &:hover {
        background-color: #3257a8;
      }
    }
  }
}
