.key-features-wrap {
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-block: 40px;
  &.with-bg {
    background: #f7f8ff;
  }
  h5 {
    margin-bottom: 60px;
    text-align: center;
  }
  .sub-heading {
    color: #515151;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-top: -50px;
    margin-bottom: 60px;
  }
  .row {
    .col-12 {
      .feature-box {
        @media screen and (max-width: 991px) {
          margin-bottom: 8px;
        }
      }
    }
    .feature-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      text-align: center;
      img {
        width: 40px;
        height: 40px;
      }
      h6 {
        color: #3257a8;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }
      p {
        color: #4e4c4b;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
      }
    }
    & + .row {
      margin-top: 60px;
      justify-content: center;
      @media screen and (max-width: 991px) {
        margin-top: 24px;
      }
    }
  }
  ul {
    margin-bottom: 0;
    li {
      margin-bottom: 12px;
      color: #4e4c4b;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      text-align: left;
      &::marker {
        font-size: 22px;
        color: #3257a8;
      }
    }
  }
}
