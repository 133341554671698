.project-overview {
  background: #fff;
  padding-block: 40px;
  .container.small {
    max-width: 726px;
    margin: 0 auto;
  }
  h2 {
    margin-bottom: 40px;
  }
  p {
    color: #515151;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 24px;
  }
  h5 {
    margin-bottom: 30px;
    text-align: left;
  }
  h6 {
    color: #515151;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 16px;
  }
  ul {
    margin-bottom: 40px;
    margin-left: -12px;
    li {
      color: #4e4c4b;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 12px;
      &::marker {
        font-size: 22px;
        color: #3257a8;
      }
    }
  }
  .project-overview-image {
    position: relative;

    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 25%;
      left: -100%;
      width: 100%;
      height: 40%;
      background: url("../../../assets/img/arrows-group-rev.png") no-repeat
        center right;
      background-size: contain;
      opacity: 0.2;
    }
  }
}

.feature-list-overview-wrap {
  position: relative;
  padding-block: 40px;
  .row {
    margin-inline: auto;
  }
  h5 {
    margin-bottom: 60px;
    text-align: center;
  }
  .row {
    &:first-child {
      .col-12 {
        padding: 0;

        .feature-box {
          position: relative;
          padding-inline: 20px;
          &::before {
            display: block;
            content: "";
            width: 100%;
            height: 1px;
            background-color: #3458a4;
            position: absolute;
            right: 0;
            top: 16px;
          }
        }
        &:first-child {
          .feature-box {
            &::before {
              width: 50%;
            }
          }
        }
      }
    }
    &:last-child {
      justify-content: flex-start !important;
      .col-12.twoCol {
        padding: 0;
        .feature-box {
          position: relative;
          padding-inline: 20px;
          &::before {
            display: block;
            content: "";
            width: 100%;
            height: 1px;
            background-color: #3458a4;
            position: absolute;
            left: 0;
            top: 16px;
          }
        }
        &:last-child {
          .feature-box {
            &::before {
              width: 50%;
            }
          }
        }
      }
    }
    .col-12 {
      .feature-box {
        @media screen and (max-width: 991px) {
          margin-bottom: 8px;
        }
      }
    }
    .feature-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      text-align: center;
      .number-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border: 1.5px solid #3458a4;
        border-radius: 50%;
        color: #3458a4;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        z-index: 1;
        background-color: #fff;
      }
      h6 {
        color: #3257a8;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }
      p {
        color: #4e4c4b;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        max-width: 75%;
      }
    }
    & + .row {
      margin-top: 60px;
      justify-content: center;
      @media screen and (max-width: 991px) {
        margin-top: 24px;
      }
    }
  }
  ul {
    margin-bottom: 0;
    li {
      margin-bottom: 12px;
      color: #4e4c4b;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      text-align: left;
      &::marker {
        font-size: 22px;
        color: #3257a8;
      }
    }
  }

  &.type2 {
    h5 {
      text-align: center;
    }
  }
}

.steps {
  display: flex;
  justify-content: center;
  .step {
    .step-number-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border: 1.5px solid #3458a4;
      border-radius: 50%;
      color: #3458a4;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      z-index: 1;
      background-color: #fff;
      margin-bottom: 10px;
    }
    .step-box {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .blue-bulltet-list {
        padding-inline: 40px;
      }
      &::before {
        display: block;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #3458a4;
        position: absolute;
        left: 0;
        top: 16px;
      }
    }
    &:first-child {
      .step-box {
        &::before {
          left: 50%;
          width: 50%;
        }
      }
    }
    &:last-child {
      .step-box {
        &::before {
          width: 50%;
        }
      }
    }
  }
}

.extra-overview-data {
  h5 {
    text-align: center;
  }
  h6 {
    color: #515151;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-top: -20px;
    margin-bottom: 30px;
  }
  ul li {
    font-size: 14px;
    strong {
      font-size: 14px;
    }
  }
  ul + h5 {
    margin-top: 80px;
  }
  p {
    font-size: 14px;
    text-align: center;
  }
}
