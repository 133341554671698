.hero-banner {
  padding-block: 40px 100px;
  background: url("../../assets/img/home-header-bg.png") no-repeat center;
  background-size: 100% 100%;
  position: relative;

  h1 {
    color: #3e4297;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
  }
  p {
    color: rgba(16, 16, 16, 0.5);
    text-align: center;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 130%;
    margin-top: 8px;
    strong {
      color: #3257a8;
    }
  }
  .banner-summary {
    position: absolute;
    bottom: -37px;
    left: 50%;
    z-index: 2;
    width: 100%;
    margin-left: -443px;
    display: flex;
    width: 100%;
    max-width: 886px;
    padding: 8px 120px;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    background: linear-gradient(286deg, #101010 -16.35%, #3257a8 107.3%);
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.1);
    .summary-info {
      .counts {
        display: block;
        color: #fff;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 150%;
      }
      .counts-label {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}
