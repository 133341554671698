input.form-control,
textarea.form-control {
  width: 100%;
  font-size: 0.875rem;
  color: $color-system-black;
  border-radius: 4px;
  border: 1px solid #d9dae8;
  &::placeholder {
    font-size: 0.875rem;
    color: rgba(16, 16, 16, 0.3);
    font-weight: 400;
    line-height: 130%;
  }
}
